import React, { Component } from 'react'
import styled from 'styled-components'

import LandingNav from './LandingNav';


const JumboTron = styled.section`
  width: 100%;
  height: 630px;
  background-image: url('/jumbo.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &:before {
    content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(20, 131, 147, 0.1), rgba(20, 131, 147, 0.1)), rgba(0, 0, 0, 0.7) !important;
        opacity: .8;
  }
`;

const JumbotronText = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  left: 24%;
  top: 30%;
  width: 824px;
  height: 195px;
  font-size: 65px;
  font-weight: bolder;
  line-height: 65px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
`;

const ProjectDetails = styled.div`
  width: 100% !important;
`;
const ProjectDetailContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const AboutProjectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
  margin-top: 128px;
  margin-bottom: 130px;
`;

const Grid = styled.div``;
const AboutProjectSection = styled.div``;

const OurApproachSection = styled.div``;
const ApproachTitle = styled.h3`
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #00BB8D;

  &::before {
    content: '';
    width: 50px;
    height: 0px;
    left: 171px;
    top: 1473px;
    background: #00BB8D;
    border: 3px solid #00BB8D;
    margin-right: 20px;
  }
`;

const ApproachSubHeading = styled.h4`
  width: 618px;
  height: 110px;
  font-size: 52px;
  font-weight: bolder;
  line-height: 49px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
`;
const ApproachDescription = styled.p`
  height: 180px;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #31393C;
`;

const Title = styled.h4`
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #00BB8D;

  &::before {
    content: '';
    width: 50px;
    height: 0px;
    left: 171px;
    top: 1473px;
    background: #00BB8D;
    border: 3px solid #00BB8D;
    margin-right: 20px;
  }
`;
const SubHeading = styled.h3`
  width: 618px;
  height: 110px;
  font-family: Averta-Bold;
  font-size: 54px;
  font-weight: bolder;
  line-height: 49px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
`;

const Description = styled.p`
  /**width: 648px;**/
  height: 180px;
    font-size: 28px;
    line-height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #31393C;
`;

const ImageWrapper = styled.div`
  width: inherit;
  height: 471px;
  overflow: hidden;
`;

const AboutProjectImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
`;


class LandingPage extends Component {
  render() {
    return (
      <React.Fragment>
        <LandingNav />
        <JumboTron>
          <JumbotronText>
            Creating a system that supports HIV self-testing in Nigeria.
          </JumbotronText>
        </JumboTron>

        <ProjectDetails>
          <ProjectDetailContainer>
            <AboutProjectContainer>
              <Grid>
                <ImageWrapper>
                  <AboutProjectImg src="/itest-bootcamp.jpg" />
                </ImageWrapper>
              </Grid>

              <Grid>
                <AboutProjectSection>
                  <Title>About Project</Title>
                  <SubHeading>A Collaborative research and development project</SubHeading>
                  <Description>ITest is a collaborative research and development project between CcHUB, NIMR and the 4yBy team. The goal of the project is to create a system that supports HIV self-testing in Nigeria by collecting test-related data and linking potential patients to care.</Description>
                </AboutProjectSection>
              </Grid>
            </AboutProjectContainer>


            <AboutProjectContainer>
              <Grid>
                <OurApproachSection>
                  <ApproachTitle>Our Approach</ApproachTitle>
                  <ApproachSubHeading>A digital tool that allow for photo-verification of test results</ApproachSubHeading>
                  <ApproachDescription>
                    Using Human-Centered design to develop a digital tool with features that allow for photo-verification of test results by professionals while also linking testers to healthcare and counseling
                  </ApproachDescription>
                </OurApproachSection>
              </Grid>

              <Grid>
                <ImageWrapper>
                  <AboutProjectImg src="/itest-program.jpg" />
                </ImageWrapper>
              </Grid>

            </AboutProjectContainer>


          </ProjectDetailContainer>
        </ProjectDetails>
      </React.Fragment>
    )
  }
}

export default LandingPage
