import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import LandingPage from './components/LandingPage';
import Privacy from './components/Privacy';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route path="/privacy" component={Privacy} />
        </Switch>
      </Router>

    </div>
  );
}

export default App;
