import React from "react";
import styled from "styled-components";

import LandingNav from "./LandingNav";

// const Header = styled.header`
// background: #FFF;
// height: 60px;
// display: flex;
// align-items: center;
// `;

const PrivacyContainer = styled.div`
  max-width: 970px;
  padding: 45px 6% 100px;
  margin: 0 auto;
  color: "#293754";
`;

const PageTitle = styled.h4`
  font-size: 22px;
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
`;

const SectionHeading = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const SectionSubHeading = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const SectionText = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const SectionTextZero = styled(SectionText)`
  margin-bottom: 0;
`;

const List = styled.ul`
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  font-size: 14px;
  margin: 0;
`;

// const Flex = styled.div`
// display: flex;
// align-items: center;
// `;

// const NavContainer = styled.div`
// width: 90%;
// margin: 0 auto;

// `;
// const Img = styled.img`
// width: 40px;
// height: 40px;
// `;

// const P = styled.p`
// margin-left: 20px;
// `;

const Privacy = () => {
  return (
    <>
      <LandingNav />
      <PrivacyContainer>
        <PageTitle>Privacy Policy</PageTitle>
        <SectionText>
          Co-creation Hub Nigeria in conjunction with the Nigerian Institute of
          Medical Research (NIMR) ("us", "we", or "our") operates the iTest
          mobile and web application (the "Service").
        </SectionText>
        <SectionText>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </SectionText>
        <SectionText>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions.
        </SectionText>

        <SectionHeading>Information Collection And Use</SectionHeading>
        <SectionText>
          We collect several different types of information for various
          purposes.
        </SectionText>

        <SectionSubHeading>Types of Data Collected</SectionSubHeading>
        <SectionText>Personal Data</SectionText>
        <SectionTextZero>
          While using our Service, we may ask you to provide us with certain
          information that can be used to contact or identify you ("Personal
          Data"). Personally identifiable information may include, but is not
          limited to:
        </SectionTextZero>
        <List>
          <ListItem>Phone number</ListItem>
          <ListItem>State, LGA</ListItem>
          <ListItem>Age</ListItem>
          <ListItem>Gender</ListItem>
        </List>

        <SectionText>Usage Data</SectionText>
        <SectionText>
          When you access the Service by or through a mobile device, we may
          collect certain information automatically, including, but not limited
          to, the type of mobile device you use, your mobile device unique ID,
          the IP address of your mobile device, your mobile operating system,
          the type of mobile Internet browser you use, unique device identifiers
          and other diagnostic data ("Usage Data").
        </SectionText>

        <SectionHeading>Use of Data</SectionHeading>
        <SectionTextZero>
          I-test uses the collected data for various purposes:
        </SectionTextZero>
        <List>
          <ListItem>To provide and maintain the Service</ListItem>
          <ListItem>To notify you about changes to our Service</ListItem>
          <ListItem>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </ListItem>
          <ListItem>To provide customer care and support</ListItem>
          <ListItem>To direct you to affordable healthcare services</ListItem>
          <ListItem>
            To provide analysis or valuable information so that we can improve
            the Service
          </ListItem>
          <ListItem>To monitor the usage of the Service</ListItem>
          <ListItem>To detect, prevent and address technical issues</ListItem>
        </List>

        <SectionHeading>Transfer Of Data</SectionHeading>
        <SectionText>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction. If you are located
          outside Nigeria and choose to provide information to us, please note
          that we transfer the data, including Personal Data, to Nigeria and
          process it there.
        </SectionText>
        <SectionText>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </SectionText>
        <SectionText>
          We will take all steps reasonably necessary to ensure that your data
          is treated securely and in accordance with this Privacy Policy and no
          transfer of your Personal Data will take place to an organization or a
          country unless there are adequate controls in place including the
          security of your data and other personal information.
        </SectionText>

        <SectionHeading>Disclosure Of Data</SectionHeading>
        <SectionSubHeading>Legal Requirements</SectionSubHeading>
        <SectionText>
          I-test may disclose your Data in the good faith belief that such
          action is necessary to:
        </SectionText>
        <List>
          <ListItem>To comply with a legal obligation</ListItem>
          <ListItem>
            To protect and defend the rights or property of I-test
          </ListItem>
          <ListItem>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </ListItem>
          <ListItem>
            To protect the personal safety of users of the Service or the public
          </ListItem>
          <ListItem>To protect against legal liability</ListItem>
        </List>

        <SectionHeading>Security Of Data</SectionHeading>
        <SectionText>
          We take the security of your information seriously and have invested
          in precautionary and safety measures to secure and protect your data
          with us – including encryption, data loss prevention mechanisms,
          2-factor authentication, a minimal access approach, among others.
        </SectionText>
        <SectionText>
          While we take care to do this, unfortunately, no security system is
          foolproof – including the channels through which you supply us your
          data and the actual systems we have put in place to safeguard them.
        </SectionText>
        <SectionText>
          You bear the responsibility of keeping your login information safe
          (including usernames, emails and passwords). In the event of a data
          breach, we will notify you within 3 days (or 72 hours) of discovery.
          If you feel at any time that your data may have been compromised (e.g,
          due to phone theft or hacking) please contact our Data Protection
          Officer (details below).
        </SectionText>

        <SectionHeading>Service Providers</SectionHeading>
        <SectionText>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf,
          to perform Service-related services or to assist us in analyzing how
          our Service is used.
        </SectionText>
        <SectionText>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </SectionText>

        <SectionSubHeading>Analytics</SectionSubHeading>
        <SectionTextZero>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </SectionTextZero>
        <List>
          <ListItem>Fabric</ListItem>
          <ListItem>
            Fabric is a platform that helps provide stability for the app. You
            can read the Privacy Policy for Fabric here:{" "}
            <a
              href="https://get.fabric.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://get.fabric.io
            </a>
          </ListItem>
        </List>

        <SectionHeading>Children's Privacy</SectionHeading>
        <SectionText>
          Our Service does not address anyone under the age of 14 ("Children").
        </SectionText>
        <SectionText>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 14. If you are a parent or guardian and you
          are aware that your Children have provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </SectionText>

        <SectionHeading>Changes To This Privacy Policy</SectionHeading>
        <SectionText>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </SectionText>
        <SectionText>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </SectionText>
        <SectionText>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </SectionText>

        <SectionHeading>Changes To This Privacy Policy</SectionHeading>
        <SectionTextZero>
          If you have any questions about this Privacy Policy, please contact
          us:
        </SectionTextZero>
        <List>
          <ListItem>
            By email: info@cchubnigeria.com or anthony@cchubnigeria.com{" "}
          </ListItem>
        </List>
      </PrivacyContainer>
    </>
  );
};

export default Privacy;
