import React from 'react'
import styled from 'styled-components'


const Header = styled.header`
  background: #FFF;
  height: 60px; 
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const NavContainer = styled.div`
  width: 90%;
  margin: 0 auto;

`;
const Img = styled.img`
  width: 40px;
  height: 40px;
`;

const P = styled.p`
  margin-left: 20px;
`;


const LandingNav = () => {
  return (
    <Header>
      <NavContainer>
        <Flex>
          <Img src="/4yby_logo.svg" />
          <P>iTest</P>

        </Flex>
        
      </NavContainer>
    </Header>
  )
}

export default LandingNav